<template>
  <div class="body_section">
    <profileHeader
      :showFilter="true"
      @changedGlobalclient="changedGlobalclient"
    />
    <div class="body_content">
      <div class="main_content">
        <div class="subheader">
          <div>
            <input
              class="search"
              v-model="serch_title"
              @keyup="serchTitle()"
              type="text"
              :placeholder="
                userdata.roleId == 5
                  ? 'Search by State'
                  : 'Search by business name and state'
              "
            />
          </div>
          <div class="d-flex">
            <transactionsFilters
              @fileExport="fileExport"
              :recordsCount="list.length"
              @applyFilter="applyFilters"
              @clearFilter="clearFilters"
              :filterStatus="filterStatus"
              :selectedGlobalClient="selectedGlobalClient"
            ></transactionsFilters>
          </div>
        </div>
        <div class="list_table">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" v-if="userdata.roleId != 5">
                  <a
                    @click="sortMe('clientName')"
                    v-bind:class="{
                      sort_ascending: sortKeys['clientName'] == 1,
                      sort_descending: sortKeys['clientName'] != 1,
                    }"
                    >Business Name</a
                  >
                </th>

                <th scope="col">
                  <a
                    @click="sortMe('totalAmount')"
                    v-bind:class="{
                      sort_ascending: sortKeys['totalAmount'] == 1,
                      sort_descending: sortKeys['totalAmount'] != 1,
                    }"
                  >
                    Order Value
                  </a>
                </th>
                <th scope="col">Order No</th>

                <th scope="col">
                  <a
                    @click="sortMe('date')"
                    v-bind:class="{
                      sort_ascending: sortKeys['date'] == 1,
                      sort_descending: sortKeys['date'] != 1,
                    }"
                    >Transaction Date</a
                  >
                </th>
                <th scope="col">Ship to (State)</th>
                <th scope="col">
                  <a
                    @click="sortMe('totalTax')"
                    v-bind:class="{
                      sort_ascending: sortKeys['totalTax'] == 1,
                      sort_descending: sortKeys['totalTax'] != 1,
                    }"
                  >
                    Sales Tax
                  </a>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="list.length > 0">
              <template v-for="(item, index) in list">
                <tr :key="index">
                  <td v-if="userdata.roleId != 5">
                    {{ checkProperty(item, "clientDetails", "busName") }}
                  </td>
                  <td>
                    <template
                      v-if="
                        checkProperty(item, 'totalAmount') != '--' &&
                          checkProperty(item, 'totalAmount') != '' &&
                          checkProperty(item, 'totalAmount') > 0
                      "
                    >
                      {{
                        checkProperty(item, "totalAmount").toFixed(2) | currency
                      }}
                    </template>
                    <template v-else>
                      $0
                    </template>
                  </td>
                  <td style="cursor:pointer" @click="showDetails(item)">
                    {{ checkProperty(item, "order") }}
                  </td>
                  <td>{{ checkProperty(item, "date") | formatDate }}</td>
                  <td>{{ checkProperty(item, "stateDetails", "name") }}</td>
                  <td>{{ checkProperty(item, "totalTax") | currency }}</td>
                  <td @click="showDetails(item)">
                    <div class="d-flex align-items-center">
                      <a href="#">DETAILS</a>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

          <template v-if="showLoading || !loaded">
            <span class="no_data list_loader"
              >Loading<img src="@/assets/images/loader.gif"
            /></span>
          </template>
          <template v-if="list.length <= 0 && loaded && !showLoading">
            <span class="no_data">No Data!</span>
          </template>
        </div>

        <div class="pagination_wrap">
          <div v-show="list.length > 0">
            <div class="pagination_content">
              <h6>Show Records</h6>
              <multiselect
                :multiple="false"
                @input="changePerPage()"
                placeholder="Page"
                :close-on-select="true"
                :clear-on-select="false"
                :hide-selected="true"
                :preserve-search="true"
                v-model="perPage"
                :options="perPeges"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && !isOpen"
                    >{{ values.length }} selected</span
                  >
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && isOpen"
                  ></span>
                </template>
              </multiselect>
            </div>
          </div>

          <template v-if="totalPages > 0 && !showLoading && loaded">
            <b-pagination
              v-if="totalPages > 0 && !showLoading && loaded"
              v-model="page"
              :total-rows="totalPages"
              :per-page="perPage"
              pills
              first-number
              last-number
            ></b-pagination>
          </template>
        </div>
      </div>

      <b-modal
        ref="transactionModal"
        centered
        hide-footer
        hide-header
        content-class="transactiondetails_popup"
        size="lg"
      >
        <div class="modal_popup profile_popup">
          <b-button class="close" block @click="hideDetails"
            ><span aria-hidden="true">&times;</span></b-button
          >
          <div class="transaction_details_modal">
            <h5>Transaction Details</h5>
            <div class="order_details">
              <ul>
                <li>
                  <span>ORDER:</span>
                  <label>{{
                    checkProperty(transactionDetails, "order")
                  }}</label>
                </li>
                <li>
                  <span>ORDER DATE:</span>
                  <label>{{
                    checkProperty(transactionDetails, "date") | formatDate
                  }}</label>
                </li>
              </ul>
            </div>
            <div class="transactions_body">
              <div class="shipping_info">
                <div class="shipping">
                  <!--
                          <label>Shipped From</label>
                         shipTo
                          -->
                </div>
                <div class="shipping">
                  <label>Shipped To</label>
                  <p>
                    {{
                      checkProperty(transactionDetails, "shipTo", "name") !=
                      "--"
                        ? checkProperty(transactionDetails, "shipTo", "name")
                        : ""
                    }}
                    <!--{{checkProperty(transactionDetails,"shipTo" ,"first_name") !="--"?checkProperty(transactionDetails,"shipTo" ,"first_name"):'' }}-->
                    {{
                      checkProperty(transactionDetails, "shipTo", "address1") !=
                      "--"
                        ? checkProperty(
                            transactionDetails,
                            "shipTo",
                            "address1"
                          )
                        : ""
                    }}
                    {{
                      checkProperty(transactionDetails, "shipTo", "city") !=
                      "--"
                        ? checkProperty(transactionDetails, "shipTo", "city")
                        : ""
                    }}

                    {{
                      checkProperty(transactionDetails, "shipTo", "phone") !=
                      "--"
                        ? checkProperty(transactionDetails, "shipTo", "phone")
                        : ""
                    }}
                    {{
                      checkProperty(transactionDetails, "shipTo", "country") !=
                      "--"
                        ? checkProperty(transactionDetails, "shipTo", "country")
                        : ""
                    }}
                    {{
                      checkProperty(transactionDetails, "shipTo", "zip") != "--"
                        ? checkProperty(transactionDetails, "shipTo", "zip")
                        : ""
                    }}
                  </p>
                </div>
              </div>
              <div class="summary_info">
                <table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Qty</th>
                      <th>Unit Price</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        checkProperty(transactionDetails, 'lineItems') != '--'
                      "
                    >
                      <tr
                        v-for="(item, index) in transactionDetails.lineItems"
                        :key="index"
                      >
                        <td>{{ checkProperty(item, "product") }}</td>
                        <td>{{ checkProperty(item, "quantity") }}</td>
                        <td>{{ checkProperty(item, "amount") | currency }}</td>
                        <td>
                          <template
                            v-if="
                              checkProperty(item, 'discount') != '' &&
                                checkProperty(item, 'discount') > 0
                            "
                          >
                            {{
                              (checkProperty(item, "amount") -
                                checkProperty(item, "discount"))
                                | currency
                            }}
                          </template>
                          <template>
                            {{ checkProperty(item, "amount") | currency }}
                          </template>
                        </td>
                      </tr>
                    </template>

                    <tr
                      v-if="
                        checkProperty(transactionDetails, 'shippingAmount') !=
                          '--' &&
                          checkProperty(transactionDetails, 'shippingAmount') >
                            0
                      "
                    >
                      <td colspan="3"><span>SHIPPING</span></td>
                      <td>
                        {{ transactionDetails.shippingAmount | currency }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr
                      v-if="
                        checkProperty(transactionDetails, 'discount') != '--' &&
                          checkProperty(transactionDetails, 'discount') > 0
                      "
                    >
                      <td colspan="3"><span>DISCOUNTS</span></td>
                      <td>{{ transactionDetails.discount | currency }}</td>
                    </tr>

                    <tr
                      v-if="
                        checkProperty(transactionDetails, 'tax') != '--' &&
                          checkProperty(transactionDetails, 'tax') > 0
                      "
                    >
                      <td colspan="3"><span>SALES TAX</span></td>
                      <td>{{ transactionDetails.tax | currency }}</td>
                    </tr>
                    <!--
                            <tr v-if="checkProperty( transactionDetails,'amount') !='--' && checkProperty( transactionDetails,'amount') >0">
                              <td colspan="3"><span>ORDER SUB TOTAL</span></td>
                              <td>{{checkProperty( transactionDetails,'amount')  }}</td>
                            </tr>
                            -->
                    <tr
                      v-if="
                        checkProperty(transactionDetails, 'totalAmount') != '--'
                      "
                    >
                      <td colspan="3"><span>ORDER TOTAL</span></td>
                      <td>{{ transactionDetails.totalAmount | currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>
<script>
import profileHeader from "@/components/ProfileHeader.vue";
import footerView from "@/components/footer.vue";
//import moment from "moment";
import Multiselect from "vue-multiselect-inv";
import transactionsFilters from "@/views/filters/transactionsFilters.vue";
import _ from "lodash";
import axios from "@/axios.js";

export default {
  name: "notes",
  methods: {
    hideDetails() {
      this.selectedTransaction = null;
      this.$refs["transactionModal"].hide();
    },
    showDetails(item) {
      this.selectedTransaction = item;

      //transactionDetails
      let postData = { transactionId: item["_id"] };
      this.transactionDetails = null;
      this.$store
        .dispatch("transactionDetails", postData)
        .then((response) => {
          this.transactionDetails = response;
          console.log();
          this.$refs["transactionModal"].show();
        })
        .catch((err) => {
          this.log(err);
        });
    },
    changedGlobalclient(cl) {
      this.selectedGlobalClient = cl;
      this.filterStatus = false;
      this.filterStatus = true;

      //this.$refs.rout_view.serchTitle(cl);
    },
    getList() {
      this.loaded = false;
      let postData = {
        filters: this.filterData,
        /*filters: {
          title: this.serch_title,
          roleIds: this.filterData.roleIds,
          statusIds: this.filterData.statusIds,
          createdOnRange: this.filterData.createdOnRange
        },
        */
        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };
      postData["filters"]["title"] = this.serch_title;
      // alert(JSON.stringify(postData.filters));

      /*
       if(!this.callfromSort){
          this.list = [];
        }
        */

      /* we need to find a way later */

      if (
        postData.filters["clientIds"] &&
        postData.filters["clientIds"].length == 0
      ) {
        // postData.filters['clientIds'].push("60b9f56b8481abd53d77f0ac");
        this.loaded = true;
        return true;
      }
      console.log("*******************************************\n");
      console.log(JSON.stringify(postData.filters));
      console.log("*******************************************\n");

      this.callfromSort = false;
      this.$store
        .dispatch("getList", { data: postData, path: "/transactions/list" })
        .then((response) => {
          this.list = response.list;
          this.totalPages = response.totalCount;
          this.loaded = true;

          document.body.scrollTop = document.documentElement.scrollTop = 0;
        })
        .catch((err) => {
          this.log(err);
        });
    },
    changePerPage() {
      this.page = 1;
      this.getList();
    },
    fileExport(filterData) {
      this.filterData.clientIds = filterData.clientIds;
      this.filterData.stateIds = filterData.stateIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.createdOnRange = filterData.createdOnRange;
      let APIURL = axios.defaults["baseURL"];
      if (_.has(filterData, "taxTypeIds")) {
        this.filterData["taxTypeIds"] = filterData["taxTypeIds"];
      }
      let postData = {
        filters: this.filterData,
        /*filters: {
          title: this.serch_title,
          roleIds: this.filterData.roleIds,
          statusIds: this.filterData.statusIds,
          createdOnRange: this.filterData.createdOnRange
        },
        */

        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: 1000000000,
      };

      console.log("************************\n");
      console.log(JSON.stringify(postData.filters));
      console.log("************************\n");
      postData["filters"]["title"] = this.serch_title;
      //alert();

      this.loaded = false;
      postData = {
        filters: this.filterData,

        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: 10000000,
      };
      postData["filters"]["title"] = this.serch_title;
      this.$store
        .dispatch("getExportFilePatch", {
          data: postData,
          path: "/transactions/export",
        })
        .then((response) => {
          this.loaded = true;
          this.exportData = response;
          //alert(JSON.stringify(APIURL+this.exportData['path']));
          //window.location.href = APIURL+"viewfile?path="+this.exportData['path'];
          window.open(
            APIURL + "viewfile?path=" + this.exportData["path"],
            "_blank"
          );
          //viewfile?path=
        })
        .catch((error) => {
          this.loaded = true;
          this.$vs.notify({
            title: "Error",
            text: error.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    applyFilters(filterData) {
      //this.filterData = filterData: { "title": "","clientIds":[] ,"statusIds": [], "stateIds": [], "createdOnRange": []},
      this.fromFillingDetails = false;
      this.filterData.clientIds = filterData.clientIds;
      this.filterData.stateIds = filterData.stateIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.createdOnRange = filterData.createdOnRange;

      if (_.has(filterData, "fromFillingDetails")) {
        this.fromFillingDetails = filterData.fromFillingDetails;
      }

      if (_.has(filterData, "taxTypeIds")) {
        this.filterData["taxTypeIds"] = filterData["taxTypeIds"];
      }
      this.filterData["taxName"] = [];
      if (_.has(filterData, "taxName") && filterData["taxName"]) {
        this.filterData["taxName"] = [filterData["taxName"]];
      }

      //alert(JSON.stringify(filterData));

      this.getList();
    },
    clearFilters() {
      this.fromFillingDetails = false;
      // alert();
      this.serch_title = "";
      (this.filterData = {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
        taxTypeIds: [],
        taxName: [],
      }),
        this.getList();
    },

    serchTitle() {
      this.page = 1;
      this.getList();
    },
    sortMe(sort_key = "") {
      this.sortKey = { path: "createdOn", order: 1 };
      if (sort_key != "") {
        this.sortKeys[sort_key] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.sortKey = {};

        this.sortKey["path"] = sort_key;
        this.sortKey["order"] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.callfromSort = true;
        this.getList();
      }
    },
  },
  data() {
    return {
      loaded: false,
      mymodal: ["mymodalsyam"],
      selectedTransaction: null,
      transactionDetails: null,
      callfromSort: false,
      selectedGlobalClient: null,
      filterStatus: true,
      stateId: null,
      clientId: null,
      filingStartDate: null,
      filingEndDate: null,
      taxName: null,
      userdata: null,
      isLoaded: false,
      loginUser: null,
      page: 1,
      perPage: 25,
      totalPages: 0,
      list: [],
      sortKey: {},
      perPeges: [10, 25, 50, 75, 100],
      filterData: {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
        taxTypeIds: [],
        taxName: [],
      },
      serch_title: "",
      sortKeys: {
        name: 1,
        clientName: 1,
        createdOn: -1,
        email: 1,
        roleName: 1,
        totalAmount: 1,
        totalTax: 1,
      },
      fromFillingDetails: false,
    };
  },
  components: {
    transactionsFilters,
    Multiselect,
    profileHeader,
    footerView,
  },
  mounted() {
    if (this.$route.query.stateId != null) {
      this.stateId = Number(this.$route.query.stateId);
      this.clientId = this.$route.query.clientId;
      this.filingStartDate = this.$route.query.filingStartDate;
      this.filingEndDate = this.$route.query.filingEndDate;
      this.taxName = this.$route.query.taxName;

      this.filterData = {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
        taxTypeIds: [],
        taxName: [],
      };
      this.filterData["stateIds"] = [];
      this.filterData["createdOnRange"] = [
        this.filingStartDate,
        this.filingEndDate,
      ];
      this.filterData["taxName"] = [this.taxName];
      this.filterData.stateIds.push(this.stateId);
      this.fromFillingDetails = false;
    }

    this.userdata = this.$store.getters["auth/getuser"];
    this.isLoaded = true;
    this.sortKey = { path: "date", order: -1 };

    this.sortKeys = {
      name: 1,
      clientName: 1,
      createdOn: -1,
      email: 1,
      roleName: 1,
      totalAmount: 1,
      totalTax: 1,
    };

    this.selectedGlobalClient = this.$store.getters["getGlobalClient"];
    this.changedGlobalclient(this.selectedGlobalClient);
    //this.filterData.clientIds
    if (
      this.selectedGlobalClient != null &&
      _.has(this.selectedGlobalClient, "_id")
    ) {
      this.filterData.clientIds.push(this.selectedGlobalClient["_id"]);
    }
    // this.getList();
  },
  watch: {
    page: function(val) {
      this.log(val);
      this.getList();
    },
  },
};
</script>
